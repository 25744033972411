import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import ContactForm from "../components/Contact/ContactForm/ContactForm"
import MainImage from "../components/Contact/MainImage/MainImage"


import { useContactQuery } from "../hooks/useContactQueries"


const Contact = ({ children }) => {

  const data = useContactQuery();

  return(
    <Layout>
        <Seo title="Home" />
        <MainImage assets={data.wpPage.ACF_ContactPage.contactHero}/>
        <ContactForm/>
    </Layout>
  )

}

export default Contact
