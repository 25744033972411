import { useStaticQuery, graphql } from "gatsby"

export const useContactQuery = ()=> {

    return useStaticQuery(graphql`
    
        query ContactQuery {
            wpPage(title: {eq: "CONTACT"}) {
                id
                ACF_ContactPage {

                    contactForm {
                        formFieldType
                        placeholderText
                        required
                    }

                    contactHero {
                        desktopImage {
                            localFile {
                                childImageSharp{
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileImage {
                            localFile {
                                childImageSharp{
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }


    `)
}