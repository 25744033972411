import React, {useRef, useEffect, useState} from "react";
import { Wrapper } from "./ContactForm.styled";
import TypeWriteLine from "../../TypeWriteLine/TypeWriteLine"
import gsap from 'gsap';


//Assets
import chooseLogo from "../../../images/choose-logo.svg"

const ContactForm = ()=> {

    const [state, setState] = useState({})
    const form = useRef();
    const $ = gsap.utils.selector(form);

    const submitForm = (event)=> {
        event.preventDefault()

        fetch("/", {
          method: "POST",
          body: encode({ 'form-name': 'contact', ...state }),
        }).then(() => {
            showMessage('Success! Will reach back to you soon.')
            event.target.reset();
        }).catch(error => {
            showMessage(`An error has ocurred [${error}].<br> Try again later.`)
            event.target.reset();
        })
    }  
    
    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }
    
    const encode = (data) => {
        const formData = new FormData()
        
        Object.keys(data).forEach(key => {
            if (key === 'files') {
              for (const file of data[key]) {
                formData.append(key, file, file.name)
              }
            } else {
              formData.append(key, data[key])
            }
        })
        
        return formData
    }

    const showMessage = (msg)=> {
        gsap.set($('.result'), {innerHTML: msg})
        new gsap.timeline()
            .from($('.result'), {duration: 0.3, opacity: 0})
    }

    useEffect(() => {
        return{

        }
    }, []);


    
    return(
        <Wrapper>
            <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
            <TypeWriteLine copy="to contact us"/>

            <form
                ref={form}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                name="contact"
                method="post"
                action="#"
                onSubmit={submitForm}
            >
                {/* You still need to add the hidden input with the form name to your JSX form */}
                <input type="hidden" name="form-name" value="contact" />

                <input className="field-style half" type="text" name="firstname" placeholder="First name" required onChange={handleChange}/> 
                <input className="field-style half last-in-line" type="text" name="lastname" placeholder="Last name" required onChange={handleChange}/> 
                
                <input className="field-style" type="email" name="email" placeholder="Email" required onChange={handleChange}/>
                <input className="field-style" type="text" name="company" placeholder="Company" required onChange={handleChange}/> 
                <input className="field-style" type="text" name="subject" placeholder="Subject" required onChange={handleChange}/> 
                
                <textarea className="field-style" name="message" cols="40" rows="10" placeholder="Your message" onChange={handleChange}></textarea>
                
                <p className="result">&nbsp;</p>
                <input className="submit" type="submit" value="SUBMIT"/>
            </form>
        </Wrapper>
    )
}

export default ContactForm;