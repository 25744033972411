import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
    ${tw`relative overflow-hidden`}
    max-height: 293px;

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`

export const DesktopImage = styled(GatsbyImage)`
    ${tw`hidden sm:block`}
`

export const MobileImage = styled(GatsbyImage)`
    ${tw`block sm:hidden`}
`