import React from "react";
import { Wrapper, DesktopImage, MobileImage } from "./MainImage.styled";

//Assets
import bottomline from "../../../images/hero-bottomline.png"

const MainImage = ({assets})=> {   
    console.log(assets) 
    return(
        <Wrapper>
            <DesktopImage image={assets.desktopImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
            <MobileImage image={assets.mobileImage.localFile.childImageSharp.gatsbyImageData} alt=""/>
            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default MainImage