import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`w-full max-w-screen-xl mx-auto py-16 px-8 xl:px-0 box-border`}

    .chooseLogo{
        ${tw`block mx-auto`}
    }

    h2{
        ${tw`block mx-auto w-max`}
    }

    form{
        ${tw`mx-auto mt-14`}
        max-width: 760px;

        input{
            ${tw`block w-full`}
        }
        textarea{
            ${tw`block w-full`}
            min-width: 100%;
            max-width: 100%;
        }

        .half{
            ${tw`block md:inline-block w-full md:w-[49%]`}

            &.last-in-line{
                ${tw`ml-[0%] md:ml-[2%]`}
            }
        }

        .field-style{
            ${tw`border-0 border border-solid border-gray outline-none bg-white mb-8 font-brandon-grotesque px-4 py-4 box-border appearance-none
            text-darkgray font-thin
            `}
            font-size: 0.9rem;

            &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
                font-size: 0.9rem;
            }
        }

        .submit{
            ${tw`
                mx-auto mt-8
                uppercase
                block bg-transparent border-lightorange text-lightorange  hover:bg-lightorange hover:text-white  transition-all cursor-pointer
                w-max text-center border-0 border-8 border-solid  px-8 py-3 rounded-full font-bold no-underline
            `}
            max-width: 240px;
            width: 100%;
        }

    }
`
